import Slider from "../../components/Common/Slider/Slider";
import CancelledFlights from "./CancelledFlights/BlogPageMorePilots";

function BlogPageMorePilots() {
  return (
    <div>
      <Slider />
      <CancelledFlights />

    </div>
  );
}

export default BlogPageMorePilots;
