module.exports = {
    en: {
        SubmitLink: {
            text: "Submit Claim"
        }
    },
    ka: {
        SubmitLink: {
            text: "შეავსეთ განაცხადი"
        }
    }
}